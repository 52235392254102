@keyframes flash {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }
}

.networkStatus {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: auto;

  a {
    color: #00b894;
    text-decoration: none;
    font-size: 0.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    fill: #00b894;
    animation: flash 5s infinite;
  }
}

.networkStatusLoading {
  @extend .networkStatus;

  a,
  svg {
    color: #f1c40f;
    fill: #f1c40f;
  }
}

.networkStatusError {
  @extend .networkStatus;

  a,
  svg {
    color: #e74c3c;
    fill: #e74c3c;
  }
}

// Modal status list styles
.statusList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.statusListItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.statusDot {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
}

.statusText {
  font-size: 0.9rem;
}
