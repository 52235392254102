@import "@carbon/styles/scss/theme";
@import "@carbon/styles/scss/spacing";

.customAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-02;
  padding: 0 $spacing-05 !important;
  width: auto;
  min-width: 3rem;
  height: 3rem;
  inline-size: 100% !important;
  //color: $text-primary;

  &:hover {
    background-color: $layer-hover;
  }

  &:focus {
    border-color: $focus;
  }
}

.actionText {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  white-space: nowrap;
  padding-left: $spacing-02;
  color: $text-secondary;

  &:hover {
    color: $text-primary;
  }
}
